// import React from 'react'
// import { Formik, Form, Field } from 'formik'
// import { Link, withRouter } from 'react-router-dom'

// import FTextField from 'ui/formik-form/FTextField'
// import { notEmptyEmailValidator } from 'utils/validators-auth.utils'
// import { getInputPlaceholder } from 'utils/text.utils'
// import { createValidationSchema } from 'utils/validators.utils'
// import { APP_USERS_SIGN_IN_PATH } from 'constants/routes/app.constants'
// import { notify } from 'libs/common/notify'
// import { getSearchParams } from 'utils/queryString.utils'

// import { sendConfirmation } from '../../api/session.api'
// import AppFormWidget from '../../components/form-widget/AppFormWidget'
// import sellerImage from '../../assets/png/sign_up_desktop.png'
// import sellerMobileImage from '../../assets/png/sign_up_mobile.png'

// import '../sign-up/sign-up.scss'

// const formValidationSchema = createValidationSchema({
//   email: notEmptyEmailValidator,
// })

// const NewConfirmationForm = withRouter(({ history }) => {
//   const { email: emailFromParams } = getSearchParams()

//   const initialValues = {
//     email: emailFromParams || '',
//   }

//   const makeNewConfirmationRequest = async ({ email }) => {
//     const resp = await sendConfirmation({ email })

//     if (resp.success) {
//       notify('success', I18n.t('react.app.form.confirmation_message'))
//       history.push(APP_USERS_SIGN_IN_PATH)
//     }
//   }

//   return (
//     <Formik
//       onSubmit={(values) => makeNewConfirmationRequest(values)}
//       initialValues={initialValues}
//       validationSchema={formValidationSchema}
//     >
//       {() => (
//         <AppFormWidget
//           leftBlockImage={{ desktop: sellerImage, mobile: sellerMobileImage }}
//           leftBlock={{ title: '', description: '' }}
//         >
//           <AppFormWidget.Title title={I18n.t('react.app.form.confirmation_title')}>
//             <span>{I18n.t('react.app.form.back_to')}</span>
//             <Link className='sing-in-to__link' to={APP_USERS_SIGN_IN_PATH}>
//               {I18n.t('react.app.button.login')}
//             </Link>
//           </AppFormWidget.Title>
//           <Form>
//             <Field
//               name='email'
//               label={I18n.t('react.app.form.email')}
//               placeholder={getInputPlaceholder('react.app.form.email', I18n.t('react.shared.email_placeholder'))}
//               component={FTextField}
//               disabled={emailFromParams}
//               className='sign-up-page-wrapper'
//             />

//             <AppFormWidget.Submit buttonText={I18n.t('react.app.button.confirmation_reset_button')} />
//           </Form>
//         </AppFormWidget>
//       )}
//     </Formik>
//   )
// })

// export default NewConfirmationForm

import React from 'react'

const NewConfirmationForm = () => <div>NewConfirmationForm</div>

export default NewConfirmationForm
